export const instagramLink = "https://www.instagram.com/agne_grimas/";

export const facebookLink = "https://www.facebook.com/agne.grimas/";

export const instagramName = "agne_grimas";

export const facebookName = "agne.grimas";

export const tel = "+370 611 34488";

export const email = "agne.hopp@gmail.com";
